import { config } from "dotenv";
// import { config } from "./../../public";

config();

const configs = {
  GOOGLE_API: process.env.REACT_APP_GOOGLE_MAPS_API,
  WOVEN_KEYS_LIVE: process.env.REACT_APP_WOVEN_KEYS_LIVE,
  FLUTTERWAVE_KEYS_LIVE: process.env.REACT_APP_FLUTTERWAVE_KEYS_LIVE,
  PAYSTACK_KEYS_LIVE: process.env.REACT_APP_PAYSTACK_KEYS_LIVE,
  PAYSTACK_KEYS_TEST: process.env.REACT_APP_PAYSTACK_KEYS_TEST,
  USERNAME: process.env.REACT_APP_USERNAME,
  PASSWORD: process.env.REACT_APP_PASSWORD,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  FLUTTER_KEY: process.env.REACT_APP_FLUTTER_KEY,
  FALL_BACK_ENDPOINT: process.env.REACT_APP_FALL_BACK_ENDPOINT,
  FALL_BACK_ENDPOINT_HIRE: process.env.REACT_APP_FALL_BACK_ENDPOINT_HIRE,
  FALL_BACK_ENDPOINT_WOVEN: process.env.REACT_APP_FALL_BACK_ENDPOINT_WOVEN,
  FALL_BACK_ENDPOINT_FLUTTER: process.env.REACT_APP_FALL_BACK_ENDPOINT_FLUTTER,
  FALL_BACK_ENDPOINT_BUDPAY: process.env.REACT_APP_FALL_BACK_ENDPOINT_BUDPAY,
  FALL_BACK_ENDPOINT_FUND_WALLET:process.env.REACT_APP_FALL_BACK_ENDPOINT_FUND_WALLET,
  FALL_BACK_ENDPOINT_WOVEN_WALLET:process.env.REACT_APP_FALL_BACK_ENDPOINT_FUND_WALLET_WOVEN,
};

export default configs;
