import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Button from "../../../src/components/Button/index";
import Alert from "../../components/Alert";
import Expire from "../../components/Expire";
import Footer from "../../components/Footer";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Navbar from "../../components/NavBar";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";


export const WovenCallBackComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [state, setState] = useState("");
  const [checkBookingVisible, setCheckBookingVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refCode, setRefCode] = useState();
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [alternativeDate, setAlternativeDates] = useState(new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738)));


  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });



  const getBookingStatus = (e) => {
    e.preventDefault();
    setError("");
    setResponseMsg("Processing");
    setLoading(true);

    request(
      apiroutes.BookingStatus(refCode),
      "get", null
    )
      .then((res) => {
        // console.log(res.data.Object);
        setResponseMsg(null);
        // setResponseMsg(res.data.payload);
        setLoading(false);
        setError(false);
        localStorage.setItem(
          "allBookingStatus",
          JSON.stringify(res.data.Object)
        );
          history.push("/bookingStatus");
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setLoading(false);
        setResponseMsg(null);
      });
  };


  const toggleModalClose = () => {
    setCheckBookingVisible(false);
  };

  const checkBookingStatus = () => {
    setCheckBookingVisible(true);
  };

  const width = 500;
  const height = 370;
  const modalBody = (
    <div>
      <div className="row">
        <div className="col-md-12">
          {error && (
            <Expire delay={3000}>
              <Alert className="alert text-center alert-danger" text={error} />
            </Expire>
          )}
          {responseMsg && !error && (
            <Expire delay={3000}>
              <Alert
                className="alert text-center alert-primary"
                text={responseMsg}
              />
            </Expire>
          )}
          <label style={{fontSize: "1.2rem", textAlign:"center"}} className="label-auth">Check booking status</label>
          <br />
          <br />
          <InputField
            type="text"
            placeholder="Enter Ref code"
            onChangeMethod={(e) => setRefCode(e.target.value)}
            value={refCode}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <Button
            text={loading ? <Loader dark={false} /> : "Proceed"}
            handleButtonClick={getBookingStatus}
            //handleButtonClick={() => {onclick(value)}}
            type="button"
            btnstyle={{
              background: "#E21D00",
              color: "white",
              margin: "20px 0px",
              fontFamily: "Euclid Circular B",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            disabled={
              !(
                refCode
              )}
          />
        </div>
      </div>
    </div>
  );

  // const copyAccountNumberToClipboard = () => {
  //   navigator.clipboard.writeText(paymentDetails.vnuban);
  //   setState("Copied!!!");

  // };
  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(paymentDetails?.account_reference);
    setState("Copied!!!");
  };

  const history = useHistory();
  let seatData = localStorage.getItem("selectedSeats");
  seatData = JSON.parse(seatData);
  let seatReturnData = localStorage.getItem("selectedSeatsReturn");
  seatReturnData =  JSON.parse(seatReturnData);
  let busData = localStorage.getItem("selectedBusData");
  busData = JSON.parse(busData);
  const tripDate = busData?.DepartureDate ? new Date(busData?.DepartureDate) : alternativeDate;
  let paymentDetails = localStorage.getItem("wovenAccountDetails");
  paymentDetails = JSON.parse(paymentDetails);
  const nf = new Intl.NumberFormat();

  return (
    <div>
      <Navbar />
      <Modal
        width={width}
        height={height}
        visible={checkBookingVisible}
        body={modalBody}
        handleClose={toggleModalClose}
      />
      <section className="suc-err-page">
        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-10 col-md-8 col-lg-6">
              <div className="ticket-card">
                <h1 className="route-card-h1">
                  Dear {paymentDetails?.account_name},
                </h1>
                <p>
                  Your reservation is successful and awaiting payment! See your
                  trip details below:
                </p>
                <p>
                  Reference number:{" "}
                  <strong>{paymentDetails?.account_reference}</strong>
                </p>
                <hr />
                <p>Kindly make payment to the accout details below:</p>
                <p>Bank: Coronation Bank</p>
                <p>
                  Account name: <strong>{paymentDetails?.account_name}</strong>
                </p>
                <p>
                  Account number: <strong>{paymentDetails?.vnuban}</strong>
                </p>
                {/* <div className="referralbtn">
                  <p>
                    Account number: <strong>{paymentDetails.vnuban}</strong>
                  </p>
                  <Button
                    text="Copy Account Number"
                    handleButtonClick={copyAccountNumberToClipboard}
                    type="button"
                  />
                  <Expire delay={2000}>
                    <p className="mt-3">{state}</p>
                  </Expire>
                  
                </div>  */}
                <p>
                  Amount to pay: ₦{" "}
                  <strong>{nf.format(paymentDetails?.max_amount)}</strong>
                </p>
                <p>
                  Reference/Description:{" "}
                  <strong>{paymentDetails?.account_reference}</strong>
                  <Button
                    text="Copy Reference Code"
                    handleButtonClick={copyCodeToClipboard}
                    type="button"
                    btnstyle={{
                      width: "150px",
                      fontSize: ".8rem",
                      marginLeft: "20px",
                      height: "35px",
                    }}
                  />
                  {/* <Expire delay={2000}> */}
                  <p
                    style={{
                      color: "green",
                      display: "inline-block",
                      fontSize: ".7rem",
                      marginLeft: "10px",
                    }}
                  >
                    {state}
                  </p>
                  {/* </Expire> */}
                </p>
                <br />
                <div className="row">
                  <div className="col-md-8">
                    <p>
                      {" "}
                      <strong>Trip Details:</strong>{" "}
                    </p>
                    <p>
                      {" "}
                      <strong>Route:</strong> {busData?.RouteName}
                    </p>
                    <p>
                      {" "}
                      <strong>Bus Type:</strong> {busData?.VehicleName}
                    </p>
                    <p>
                      {" "}
                      <strong>Trip Date:</strong> {dateTimeFormat.format(tripDate)}
                    </p>
                    <p>
                      {" "}
                      <strong>Seat No:</strong> {seatData.toString()}
                    </p>
                    {seatReturnData === null ? "" :
                    <p>
                      {" "}
                      <strong>Returned Seat No:</strong> {seatReturnData.toString()}
                    </p>
}
                  </div>
                  <div className="col-md-4">
                    <p>
                      {" "}
                      <strong>Price:</strong>{" "}
                    </p>
                    <p>₦{nf.format(paymentDetails?.max_amount)}</p>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Button
                  handleButtonClick={checkBookingStatus}
                  // handleButtonClick={() => {
                  //   history.push("/");
                  // }}
                  text="Check Booking Status"
                  type="button"
                  btnstyle={{
                    backgroundColor: "#E21D00",
                    margin: "20px 0px",
                    width: "70%",
                  }}
                />
              </div>
              {/* <h3>Reference Code: {reference}</h3> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default WovenCallBackComponent;
