import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import Navbar from "../../components/NavBar";
import "./selfCheck.css";
// import Alert from "../../components/Alert";
// import Expire from "../../components/Expire";
import { useHistory } from "react-router";
import config from "../../configs";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { setAuth } from "../../services/auth";

export const CheckInComponent = () => {
  useEffect(() => {
    getToken();
  }, []);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [refCode, setRefCode] = useState();
  // const [error, setError] = useState("");
  // const [responseMsg, setResponseMsg] = useState("");

  const getToken = () => {
    const formEncoded = new URLSearchParams({
      // username: config.USERNAME,
      // password: config.PASSWORD,
      client_id: config.CLIENT_ID,
      grant_type: config.GRANT_TYPE,
      client_secret: config.CLIENT_SECRET,
    });

    request(apiroutes.GetToken(), "post", formEncoded)
      .then((res) => {
        const data = {
          access_token: res.data.access_token,
          expires_in: res.data.expires_in,
          profilePix: res.data.profilePix,
          roles: res.data.roles,
          user_id: res.data.user_id,
          userassignedmenu: res.data.userassignedmenu,
          username: res.data.username,
        };
        setAuth(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getBookingStatus = (e) => {
    e.preventDefault();

    setLoading(true);

    request(apiroutes.BookingStatus(refCode), "get")
      .then((res) => {
        if (
          res.data?.Object?.length === 0 ||
          res.data.Object?.refCode === null
        ) {
          setLoading(false);
          // alert("Invalid RefCode!!!, Please try again.");
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Invalid RefCode!!!, Please try again.`,
            icon: "error",
          });
          setTimeout(() => window.location.reload(), 1000);
        } else if (res.data?.Object === null) {
          setLoading(false);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: res.data?.ShortDescription,
            icon: "error",
          });
        } else {
          // console.log(res.data.Object);
          setLoading(false);
          localStorage.setItem(
            "allBookingStatus",
            JSON.stringify(res.data.Object)
          );
          history.push("/bookingStatus");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <Navbar />
      <section className="">
        <div className="container h-100">
          <div className="row self-check">
            <div className="col-md-6 col-sm -12">
              <label className="label-auth">
                <b>Check In</b>
              </label>
              <br />
              <br />
              <InputField
                type="text"
                placeholder="Enter Ref code"
                onChangeMethod={(e) => setRefCode(e.target.value)}
                value={refCode}
                customStyles={{ color: "black" }}
              />
            </div>
            <div className="col-md-12">
              <Button
                text={loading ? <Loader dark={false} /> : "Check In"}
                handleButtonClick={getBookingStatus}
                //handleButtonClick={() => {onclick(value)}}
                type="button"
                btnstyle={{
                  background: "red",
                  color: "white",
                  margin: "20px 0px",
                  // fontFamily: "Euclid Circular B",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  width: "200px",
                }}
                disabled={!refCode}
              />
            </div>
          </div>
          <br />
          <div className="row"></div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default CheckInComponent;
