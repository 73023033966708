import PropTypes from "prop-types";
import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "./index.css";

const ModalComponentPopup = ({
  visible,
  handleClose,
  title,
  paragraph,
  body,
  width,
  height,
}) => {
  return (
    <Rodal
      visible={visible}
      onClose={handleClose}
      width={width}
      height={height}
    >
      <div
        style={{
          overflow: "hidden",
          height: "500px",
          // backgroundColor:"#56CCF2"
        }}
      >
        <div className="modContainer-popup">
          {/* <div className="modalTitle">{title}</div> */}
          {/* <div className="modalPar">{paragraph}</div> */}
          <div style={{ marginTop: "10px" }}>{body}</div>
        </div>
      </div>
    </Rodal>
  );
};

ModalComponentPopup.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number || PropTypes.string,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  body: PropTypes.element.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalComponentPopup;
