import React, { useEffect } from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import "./index.css";
import {formatCurrency} from "../../components/util";


export const BankTransferComponent = () => {
  let paymentData = localStorage.getItem("hireEmailAmount");
  paymentData = JSON.parse(paymentData);
  // console.log(paymentData);

  let paymentAmountRounded = paymentData.amount
  paymentAmountRounded = Math.round( paymentAmountRounded * 10 ) / 10;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div>
      <Navbar />
      <section className="">
        <div className="container h-100">
          <h5 className="blink_me mb-2" style={{ fontWeight: "bold", paddingTop: "150px", color:"red", fontSize:"1.8rem" }}>
            Transaction Pending
          </h5>
          <p className="mb-5" style={{opacity:".7"}}>Thank you for choosing GIGM.com</p>
          <span className="payment-alert mb-3">Kindly note that this booking would expire after 1 hour if payment is not made</span>
          <p>Please pay the sum of <strong>&#35;{" "}{formatCurrency(paymentAmountRounded)}</strong> into the account details below, stating your reference code as <strong>{paymentData.refCode}</strong></p>
          <div className="bookingStatusDetails h-100">
            <div className="book-det flex-column">
              <div className="info-det">
                <label className="det-label">Bank Name</label>
                <p>
                  <strong>Union Bank Plc</strong>
                </p>
              </div>
              <br/>
              <div className="info-det">
                <label className="det-label">Account Name</label>
                <p>
                  <strong>GIG MOBILITY LTD</strong>
                </p>
              </div>
              <br/>
              <div className="info-det">
                <label className="det-label">Account Number</label>
                <p>
                  <strong>0069883140</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default BankTransferComponent;
