import React, { useEffect, useState } from "react";
import logo from "../../assets/img/Layer x0020 1.png";
import "./login.css";
// import google from "../../assets/img/google (1) 1.svg";
// import facebook from "../../assets/img/facebook.png";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import eye from "../../assets/img/eye-24-512.png";
import eyeHidden from "../../assets/img/invisible 2.png";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Expire from "../../components/Expire";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth, setUser } from "../../services/auth";

export const LoginComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordField, setPasswordField] = useState("password");
  const [modalVisible, setVisible] = useState(false);
  const [modalVisibleConfirm, setVisibleConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const history = useHistory();
  const [verificationCode, setVerificationCode] = useState("");
  const [modalVisibleVerify, setVisibleVerify] = useState(false);

  const token = getAuth("access_token");

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
    setPasswordField(passwordField === "text" ? "password" : "text");
  };

  const toggleModal = () => {
    setVisible(true);
  };
  const toggleModalCloseVerify = () => {
    setVisibleVerify(true);
  };

  // const resendVerification = () => {
  //   setVisibleConfirm(false);
  //   setVisible(true);
  // };

  const toggleModalClose = () => {
    setVisible(false);
  };

  const toggleModalCloseTwo = () => {
    setVisibleConfirm(false);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    // setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    const verify = {
      UserName: phoneNumber ? phoneNumber : email,
    };
    request(apiroutes.ForgotPassword(), "post", verify, token)
      .then((res) => {
        // console.log(res, 'verify code');
        if (
          res.data.Code === null ||
          res.data.Object === null ||
          res.data.Object === null
        ) {
          setLoading(false);
          setResponseMsg(null);
          // setError(res.data.ShortDescription);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.ShortDescription}`,
            icon: "error",
          });
          // setTimeout(() => setError(""), 4000)
        } else {
          setLoading(false);
          // setResponseMsg("Verification Code Sent");
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Verification Code Sent`,
            icon: "success",
          });
          setVisible(false);
          setVisibleConfirm(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setResponseMsg(null);
        setLoading(false);
      });
  };
  const handleForgotPasswordVerify = () => {
    // setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    const verify = {
      UserName: phoneNumber ? phoneNumber : email,
    };
    request(apiroutes.ForgotPassword(), "post", verify, token)
      .then((res) => {
        // console.log(res, 'verify code');
        if (
          res.data.Code === null ||
          res.data.Object === null ||
          res.data.Object === null
        ) {
          setLoading(false);
          setResponseMsg(null);
          // setError(res.data.ShortDescription);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.ShortDescription}`,
            icon: "error",
          });
          // setTimeout(() => setError(""), 4000)
        } else {
          setLoading(false);
          // setResponseMsg("Verification Code Sent");
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Verification Code Sent`,
            icon: "success",
          });
          // setVisible(false);
          // setVisibleConfirm(true);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setResponseMsg(null);
        setLoading(false);
      });
  };

  const verifyCode = (e) => {
    e.preventDefault();
    // setResponseMsg("Processing");
    setLoading(true);
    const verifyDetails = {
      Username: email,
      VerificationCode: verificationCode,
    };
    request(apiroutes.verifyPhoneNumber(), "post", verifyDetails, token)
      .then((res) => {
        // console.log(res, 'lsign up data');
        if (
          res.data.Code === null ||
          res.data.Object === null ||
          res.data.Object === null
        ) {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            // text: `Password Doesn't Match`,
            text: `${res.data.ShortDescription}`,
            icon: "error",
          });
          // setError(res.data.ShortDescription);
          // setTimeout(() => setError(""), 4000)
        } else {
          setLoading(false);
          // setResponseMsg("Phone Number Verified");
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Phone Number Verified`,
            // text: `${res.data.ShortDescription}`,
            icon: "success",
          });
          // setVisibleTwo(true);

          const data = {
            Email: res.data.Object?.Email,
            FirstName: res.data.Object?.FirstName,
            Gender: res.data.Object?.Gender,
            Image: res.data.Object?.Image,
            IsActive: res.data.Object?.IsActive,
            NextOfKinName: res.data.Object?.NextOfKinName,
            NextOfKinPhone: res.data.Object?.NextOfKinPhone,
            PhoneNumber: res.data.Object?.PhoneNumber,
            ReferralCode: res.data.Object?.ReferralCode,
            UserId: res.data.Object?.UserId,
            UserType: res.data.Object?.UserType,
          };
          setUser(data);
          setTimeout(() => history.push("/"), 1500);
        }
      })
      .catch((err) => {
        setResponseMsg(null);
        setLoading(false);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err.data.shortDescription}`,
          icon: "error",
        });
      });
  };

  const toggleModalConfirm = (e) => {
    e.preventDefault();
    setVisibleConfirm(false);
    history.push("/forgot-password");
  };

  // const  checkEmail = () => {
  //   var filter =
  //     /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  //   if (!filter.test(email)) {
  //     Swal.fire({
  //       showConfirmButton: false,
  //       timer: 4000,
  //       text: `Please provide a valid email address`,
  //       icon: "error",
  //     });
  //     return false;
  //   } else {
  //     handleSubmit()
  //   }
  // }
  // const  checkEmailForgot = () => {
  //   var filter =
  //     /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  //   if (!filter.test(phoneNumber)) {
  //     Swal.fire({
  //       showConfirmButton: false,
  //       timer: 4000,
  //       text: `Please provide a valid email address`,
  //       icon: "error",
  //     });
  //     return false;
  //   } else {
  //     handleForgotPassword()
  //   }
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    const loginDetails = {
      UserName: email,
      Password: password,
    };
    request(apiroutes.Login(), "post", loginDetails, token)
      .then((res) => {
        // console.log(res, 'login data');
        if (
          res.data.Code === null ||
          res.data.Object === null ||
          res.data.Object === null
        ) {
          setLoading(false);
          setResponseMsg(null);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            // text: `Verification Code Sent`,
            text: `${res.data.ShortDescription}`,
            icon: "error",
          });
          // setError(res.data.ShortDescription);
          // setTimeout(() => setError(""), 4000)
        } else if (res.data.Object.IsActive === false) {
          handleForgotPasswordVerify();
          setVisibleVerify(true);

          setTimeout(() => setResponseMsg(""), 4000);
        } else {
          setLoading(false);
          setResponseMsg("Logged In Successfully");
          // Swal.fire({
          //   showConfirmButton: false,
          //   timer: 4000,
          //   text: `Logged In Successfully`,
          //   icon: "success",
          // });

          const data = {
            Email: res.data.Object.Email,
            FirstName: res.data.Object.FirstName,
            Gender: res.data.Object.Gender,
            Image: res.data.Object.Image,
            IsActive: res.data.Object.IsActive,
            NextOfKinName: res.data.Object.NextOfKinName,
            NextOfKinPhone: res.data.Object.NextOfKinPhone,
            PhoneNumber: res.data.Object.PhoneNumber,
            ReferralCode: res.data.Object.ReferralCode,
            UserId: res.data.Object.UserId,
            UserType: res.data.Object.UserType,
          };
          setUser(data);
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err.response);
        setResponseMsg(null);
        setLoading(false);
      });
  };

  const width = 500;
  const height = 350;
  const heightt = 450;
  const modalTitle = "Forgot Password?";
  const modalPar =
    "Please type in the email address linked to your GIGM account";
  const modalBody = (
    <div>
      <label htmlFor="email" className="label-auth">
        Email
        {/* Email{" "}/{" "}Phone Number */}
      </label>
      <InputField
        type="text"
        placeholder="Email"
        onChangeMethod={(e) => setPhoneNumber(e.target.value)}
      />
      <br />
      <br />
      <Button
        // text={loading ? <Loader dark={false} /> : "Get Verification Code"}
        text={
          loading ? (
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                transform: "translateX(-10px)",
              }}
            >
              <Loader dark={false} />{" "}
              <span
                style={{
                  display: "inline-block",
                  transform: "translateX(-10px)",
                }}
              >
                Processing
              </span>
            </div>
          ) : (
            "Get Verification Code"
          )
        }
        // handleButtonClick={checkEmailForgot}
        handleButtonClick={handleForgotPassword}
        disabled={!phoneNumber}
        type="button"
      />
    </div>
  );

  const modalTitleTwo = "Verification code";
  const modalParTwo =
    "Hi there, we just sent a verification code to your provided email and phone number";
  const modalBodyTwo = (
    <div className="text-center">
      <br />
      <p>
        Didn’t get verification code?{" "}
        <span
          onClick={handleForgotPassword}
          // onClick={resendVerification}
          style={{ color: "#56CCF2", cursor: "pointer" }}
        >
          Resend
        </span>
      </p>
      <br />
      <br />
      <Button
        text="Click To Reset Your Password"
        // text="Get Verification Code"
        handleButtonClick={toggleModalConfirm}
        type="button"
      />
    </div>
  );

  const modalTitleVerify = "Enter Verification Code";
  const modalParVerify =
    "Please check your Email/Phone and Enter the Verification Code.";
  const modalBodyVerify = (
    <div>
      <label htmlFor="email" className="label-auth">
        Verification Code
      </label>
      <InputField
        type="text"
        placeholder="Verification code"
        onChangeMethod={(e) => setVerificationCode(e.target.value)}
      />
      <br />
      <div className="text-center">
        <br />
        <p>
          Didn’t get verification code?{" "}
          <span
            onClick={handleForgotPassword}
            // onClick={resendVerification}
            style={{ color: "#56CCF2", cursor: "pointer" }}
          >
            Resend
          </span>
        </p>
        <br />
      </div>
      <br />
      <Button
        // text={loading ? <Loader dark={false} /> : "Proceed"}
        text={
          loading ? (
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                justifyContent: "center",
                alignItems: "center",
                width: "200px",
                transform: "translateX(-10px)",
              }}
            >
              <Loader dark={false} />{" "}
              <span
                style={{
                  display: "inline-block",
                  transform: "translateX(-10px)",
                }}
              >
                Processing
              </span>
            </div>
          ) : (
            "Proceed"
          )
        }
        handleButtonClick={verifyCode}
        disabled={!verificationCode}
        type="button"
      />
    </div>
  );

  return (
    <div>
      <Modal
        width={width}
        height={heightt}
        visible={modalVisibleVerify}
        title={modalTitleVerify}
        paragraph={modalParVerify}
        body={modalBodyVerify}
        handleClose={toggleModalCloseVerify}
      />
      <Modal
        width={width}
        height={height}
        visible={modalVisible}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBody}
        handleClose={toggleModalClose}
      />

      <Modal
        width={width}
        height={height}
        visible={modalVisibleConfirm}
        title={modalTitleTwo}
        paragraph={modalParTwo}
        body={modalBodyTwo}
        handleClose={toggleModalCloseTwo}
      />

      <div className="d-md-flex h-md-100">
        <div className="col-md-5 bg-indigo h-md-100">
          <div className="row">
            <div className="col-md-12">
              <div className="this-position">
                <Link to="/">
                  <img src={logo} alt="" className="home-logo" />
                </Link>
                <h1>Revolutionizing road transportation in Africa</h1>
                <p>
                  GIGM is a technologically powered mobility platform providing
                  MOBILITY services to people across Africa
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 h-md-100">
          <div className="d-md-flex align-items-center justify-content-center h-md-100">
            <div className="pt-0">
              <div className="auth-section">
                <div className="row justify-content-md-center">
                  <div className="col-12">
                    {error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-danger"
                          text={error}
                        />
                      </Expire>
                    )}
                    {responseMsg && !error && (
                      <Expire delay={3000}>
                        <Alert
                          className="alert text-center alert-primary"
                          text={responseMsg}
                        />
                      </Expire>
                    )}
                  </div>
                </div>
                <h1>Sign into your GIGM account using</h1>
                {/* <div className="text-center">
                  <button className="btn-social">
                    <img src={google} alt="" />
                    &nbsp; &nbsp; Google
                  </button>
                  &nbsp; &nbsp;
                  <button className="btn-social">
                    <img src={facebook} alt="" />
                    &nbsp; &nbsp; Facebook
                  </button>
                </div>
                <span className="breaking-or">Or</span> */}
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email" className="label-auth">
                      Email / Phone Number
                    </label>
                    <InputField
                      type="email"
                      placeholder="Example@gmail.com"
                      onChangeMethod={(e) => setEmail(e.target.value)}
                      value={email}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="password" className="label-auth">
                      Password
                    </label>
                    <div className="pass-wrapper">
                      <InputField
                        type={passwordField}
                        placeholder="Enter Your Password"
                        onChangeMethod={(e) => setPassword(e.target.value)}
                        value={password}
                        autoComplete="new-password"
                      />

                      {showPassword ? (
                        <img
                          src={eye}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible img-size"
                        />
                      ) : (
                        <img
                          src={eyeHidden}
                          alt=""
                          onClick={showPasswordToggle}
                          className="passwordVisible"
                        />
                      )}
                    </div>
                    <p className="forgot-password" onClick={toggleModal}>
                      Forgot Password?
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                    <Button
                      text={loading ? <Loader dark={false} /> : "Sign In"}
                      handleButtonClick={handleSubmit}
                      // handleButtonClick={checkEmail}
                      type="button"
                      disabled={!(email && password)}
                    />
                    <p className="forgot-password">
                      Don't have an Account? <Link to="/register">Sign Up</Link>
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3">
                    <h2>Why sign up on GIGM</h2>
                    <ul>
                      <li>Get Additional 5% off</li>
                      <li>Manage your booking</li>
                      <li>Get exclusive deals and offers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginComponent;
