import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import "./payFlutter.css";
// import InputField from "../../components/InputField";
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { useHistory } from "react-router";
import Loader from "../../components/Loader";
// import { hslToRgb } from "@material-ui/core";
import logo from "../../assets/img/Layer x0020 1.png";



export const PayFlutterComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = localStorage.getItem("transEmailAmount");
    data = JSON.parse(data);
    setInfo(data)
  }, [])
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const [info, setInfo] = useState("");

  const configg = {
    public_key: "FLWPUBK-8f81a62f610023bf89b90e0e32d7cec5-X",
    // public_key: config.FLUTTER_KEY,
    tx_ref: info?.refCode,
    amount: info?.amount,
    currency: 'NGN',
    redirect_url: "https://gigm.com/flutter-pay",
    // redirect_url: config.FALL_BACK_ENDPOINT_FLUTTER,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: info?.email,
      phonenumber: info?.phonenumber,
    },
    // customizations: {
    //   title: 'my Payment Title',
    //   description: 'Payment for items in cart',
    //   logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    // },
  };

  const handleFlutterPayment = useFlutterwave(configg);

  return (
    <div>
      <Navbar />
      <section className="">
        <div className="container h-100">
          <div className="flutter row">
            <div className="">
            <img src={logo} alt="" width="150" />
            </div>
            <br/>
            <div className="">
              <Button
                text={loading ? <Loader dark={false} /> : "Pay With FlutterWave"}
                handleButtonClick={() => {
                  setLoading(true)
                  handleFlutterPayment({
                    callback: (response) => {
                      //  console.log(response);
                        closePaymentModal() // this will close the modal programmatically
                    },
                    onClose: () => {history.push("/flutter-pay")},
                  });
                }}
                type="button"
                btnstyle={{
                  background: "red",
                  color: "white",
                  margin: "20px 0px",
                  // fontFamily: "Euclid Circular B",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  width: "200px"
                }}
                // disabled={!refCode}
              />
            </div>
          </div>
          <br />
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default PayFlutterComponent;
