// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/bulleting 3.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".auth-section ul {\n    margin-left: 10px;\n    list-style: none;\n}\n\n.auth-section li {\n    padding: 10px 0 0px 5px;\n    list-style-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 20px;\n    color: #333333;\n}", "",{"version":3,"sources":["webpack://src/views/LoginPage/login.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,yDAAyD;IACzD,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".auth-section ul {\n    margin-left: 10px;\n    list-style: none;\n}\n\n.auth-section li {\n    padding: 10px 0 0px 5px;\n    list-style-image: url(\"../../assets/img/bulleting 3.png\");\n    font-style: normal;\n    font-weight: normal;\n    font-size: 14px;\n    line-height: 20px;\n    color: #333333;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
