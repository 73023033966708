import React from "react";
import CookieConsent from "react-cookie-consent";
import TagManager from "react-gtm-module";
import "./assets/css/App.css";
import AllPages from "./routes/routes";

const tagManagerArgs = {
  gtmId: "GTM-NCBTVTL",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <>
      <AllPages />
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        cookieName="gigmCookies"
        style={{
          background: "#ffffff",
          color: "black",
          minHeight: "100px",
          // width: "60%",
          display: "flex",
          alignItems: "center",
        }}
        buttonStyle={{
          background: "#007bff",
          color: "white",
          fontSize: "13px",
          fontWeight: "bold",
          marginTop: "10px 40px",
          borderRadius: "5px",
          padding: "5px 15px",
          width: "250px",
        }}
        declineButtonStyle={{
          background: "red",
          color: "white",
          fontSize: "13px",
          fontWeight: "bold",
          marginTop: "10px",
          borderRadius: "5px",
          padding: "5px 15px",
        }}
        expires={150}
        // disableStyles={true}
        buttonClasses="btn btn-primary"
        containerClasses="alert alert-warning col-lg-12 text-center"
        // contentClasses="text-capitalize"
        // enableDeclineButton
        flipButtons
        overlay
        // onAccept={() => {
        //   console.log(getCookieConsentValue(), "COOKIES");
        // }}
        // visible
      >
        We use your data to give you the best experience on our website. By
        continuing without changing your Cookie settings, we assume you agree to
        our{" "}
        <span>
          <a style={{ color: "#007bff" }} href="/Terms-and-Conditions">
            Terms And Conditions.
          </a>
        </span>
        <br />
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </>
  );
}

export default App;
