import React, { useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Expire from "../../components/Expire";
import InputField from "../../components/InputField";
import Loader from "../../components/Loader";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const BookStatus = () => {
  const token = getAuth("access_token");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [refCode, setRefCode] = useState();
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  const getBookingStatus = (e) => {
    e.preventDefault();
    setError("");
    setResponseMsg("Processing");
    setLoading(true);

    request(apiroutes.BookingStatus(refCode), "get", null, token)
      .then((res) => {
        if (
          res.data?.Object?.length === 0 ||
          res.data.Object?.refCode === null
        ) {
          setLoading(false);
          setResponseMsg(null);
          // console.log(typeof res.data.Object, "OBJECT TYPE")
          // alert(`Booking Status not found for this particular Booking Reference ${refCode}, Please try again.`);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Booking Status not found for this particular Booking Reference ${refCode}, Please try again.`,
            icon: "error",
          });
          setTimeout(() => window.location.reload(), 1000);
        } else if (res.data?.Object === null) {
          setLoading(false);
          setResponseMsg(null);
          // console.log(typeof res.data.Object, "OBJECT TYPE")
          // alert(`Booking Status not found for this particular Booking Reference ${refCode}, Please try again.`);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: res.data?.ShortDescription,
            icon: "error",
          });
          // setTimeout(() => window.location.reload(), 1000)
        } else {
          // console.log(res.data.Object);
          setResponseMsg(null);
          setLoading(false);
          setError(false);
          localStorage.setItem(
            "allBookingStatus",
            JSON.stringify(res.data.Object)
          );
          history.push("/bookingStatus");
        }
      })
      .catch((err) => {
        // if(err.data.Message === "Authorization has been denied for this request") {
        //   alert("Hi")
        // }
        console.log(err, "errrrrr");
        setError(err);
        setLoading(false);
        setResponseMsg(null);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {error && (
            <Expire delay={3000}>
              <Alert className="alert text-center alert-danger" text={error} />
            </Expire>
          )}
          {responseMsg && !error && (
            <Expire delay={3000}>
              <Alert
                className="alert text-center alert-primary"
                text={responseMsg}
              />
            </Expire>
          )}
          <label className="label-auth">Check booking status</label>
          <br />
          <br />
          <InputField
            type="text"
            placeholder="Enter Reference Code"
            onChangeMethod={(e) => setRefCode(e.target.value)}
            value={refCode}
            customStyles={{ color: "white" }}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <Button
            text={loading ? <Loader dark={false} /> : "Proceed"}
            handleButtonClick={getBookingStatus}
            //handleButtonClick={() => {onclick(value)}}
            type="button"
            btnstyle={{
              background: "#E21D00",
              color: "white",
              margin: "20px 0px",
              // fontFamily: "Euclid Circular B",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            disabled={!refCode}
          />
        </div>
      </div>
    </div>
  );
};
export default BookStatus;
