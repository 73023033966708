import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Select from "../../components/Dropdown/index";
import Loader from "../../components/Loader";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth } from "../../services/auth";

export const BookSeat = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("adults");
    localStorage.removeItem("adultsRound");
  }, []);
  const [departure, setDeparture] = useState("");
  const [adult, setAdult] = useState("1");
  const [arrival, setArrival] = useState("");
  // const [startDate, setStartDate] = useState("");
  var date = new Date();
  var date2 = new Date();
  // add a day
  // date.setDate(date.getDate() + 1);
  const [startDate, setStartDate] = useState(date.setDate(date.getDate() + 1));
  // const [startDate, setStartDate] = useState(new Date());
  const [departureRound, setDepartureRound] = useState("");
  const [adultRound, setAdultRound] = useState("1");
  const [arrivalRound, setArrivalRound] = useState("");
  const [startDateRound, setStartDateRound] = useState(
    date2.setDate(date2.getDate() + 1)
  );
  // const [startDateRound, setStartDateRound] = useState(new Date());
  const [endDateRound, setEndDateRound] = useState(null);
  const token = getAuth("access_token");
  const [arrivalTerminal, setArrivalTerminal] = useState([]);
  // const [departureTerminalRound, setDepartureTerminalRound] = useState([]);
  const [arrivalTerminalRound, setArrivalTerminalRound] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageLoadingRound, setPageLoadingRound] = useState(false);

  // let arrivalSelect = arrivalTerminal.filter((x) => {
  //   return x.TerminalId !== 64 && x.TerminalId !== 68 && x.TerminalId !== 69;
  // });
  const arrivalSelect = arrivalTerminal
    ? arrivalTerminal.map((x) => ({
        label: x.TerminalName,
        value: x.TerminalId,
      }))
    : "";
  const adults = [];
  for (let i = 1; i <= 13; i++) {
    adults.push(i);
  }
  const adultOptions = adults.map((x) => ({ label: x, value: x }));

  // const departureSelectRound = departureTerminalRound.map((x) => ({
  //   label: x.TerminalName,
  //   value: x.TerminalId,
  // }));
  const arrivalSelectRound = arrivalTerminalRound.map((x) => ({
    label: x.TerminalName,
    value: x.TerminalId,
  }));
  const adultsRound = [];
  for (let i = 1; i <= 13; i++) {
    adultsRound.push(i);
  }
  const adultOptionsRound = adultsRound.map((x) => ({ label: x, value: x }));

  const history = useHistory();

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "black",
      background: "white",
    }),

    menu: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      // color: state.isSelected ? 'red' : 'blue',
      // padding: 20,
      background: "white",
    }),
  };

  // useEffect(() => {
  //   const getDepartureTerminal = () => {
  //     request(apiroutes.DepartureTerminals(), "get", null, token)
  //       .then((res) => {
  //         setDepartureTerminal(res.data.Object.Items);
  //       })
  //       .catch((err) => {
  //         console.log(err.response);
  //       });
  //   };
  //   getDepartureTerminal();
  // }, [token]);
  const terminalOptions = [
    { id: 9, name: "Abia => Aba" },
    // { id: 38, name: "Abia => Bende Local Government Area Camp" },
    { id: 8, name: "Abia => Umuahia" },
    { id: 34, name: "Cross Rivers => Calabar" },
    { id: 10, name: "Akwa Ibom => Uyo" },
    { id: 11, name: "Anambra => Awka" },
    { id: 104, name: "Oyo => Ibadan" },
    { id: 99, name: "Ebonyi => Abakaliki" },
    // { id: 76, name: "Ashanti => Anyinasi" },
    // { id: 78, name: "Ashanti => Bonsu" },
    // { id: 74, name: "Ashanti => Konongo" },
    // { id: 66, name: "Ashanti => Kumasi" },
    // { id: 75, name: "Ashanti => Nkawkaw" },
    { id: 12, name: "Bayelsa => Yenagoa" },
    { id: 13, name: "Delta(South-East) => Asaba (Onitsha)" },
    // { id: 41, name: "Delta(South-South) => Aniocha North L.G.A. Camp" },
    { id: 35, name: "Delta(South-South) => Warri" },
    { id: 4, name: "Edo => Auchi" },
    { id: 14, name: "Edo => Benin (Akpakpava)" },
    { id: 5, name: "Edo => Benin (Uselu)" },
    { id: 15, name: "Edo => Ekpoma" },
    { id: 17, name: "Enugu => Enugu" },
    { id: 72, name: "Enugu => Nsukka" },
    { id: 20, name: "FCT Abuja => Kubwa" },
    // { id: 37, name: "FCT Abuja => Kubwa, Bwari Area Council Camp" },
    { id: 60, name: "FCT Abuja => Madalla" },
    { id: 18, name: "FCT Abuja => Mararaba" },
    { id: 21, name: "FCT Abuja => Utako" },
    { id: 19, name: "FCT Abuja => Zuba" },
    // { id: 64, name: "Greater Accra => Accra" },
    { id: 22, name: "Imo => Owerri" },
    { id: 24, name: "Kaduna => Kaduna" },
    // { id: 45, name: "Kaduna => Kaduna – Abuja Road Camp" },
    // { id: 61, name: "Lagos => Admiralty" },
    { id: 7, name: "Lagos => Ajah" },
    // { id: 57, name: "Lagos => AJAHBACKWARD" },
    { id: 63, name: "Lagos => Akowonjo (Dopemu)" },
    { id: 49, name: "Lagos => Cele/Okota" },
    { id: 25, name: "Lagos => Festac (mazamaza)" },
    // { id: 59, name: "Lagos => Head Office" },
    { id: 27, name: "Lagos => Ikotun" },
    { id: 6, name: "Lagos => Iyana Ipaja" },
    { id: 29, name: "Lagos => Jibowu" },
    { id: 33, name: "Lagos => Old Ojo Road" },
    // { id: 30, name: "Lagos => Oyingbo (Iddo)" },
    { id: 26, name: "Lagos => Volks" },
    { id: 28, name: "Lagos => Yaba" },
    // { id: 68, name: "Lome => Lome" },
    // { id: 69, name: "Ouémé => Cotonou" },
    // { id: 46, name: "Plateau => Jakatai-Mangu Mangu LGA Camp" },
    { id: 31, name: "Plateau => Jos" },
    { id: 32, name: "Rivers => Port Harcourt" },
    // { id: 42, name: "Rivers => Tai Local Government Area Camp" },
    // { id: 70, name: "Volta => Aflao" },
    // { id: 77, name: "Volta => Sogatokpe" },
  ];

  const optionTerminal = terminalOptions.map((x) => ({
    label: x.name,
    value: x.id,
  }));

  const getArrivalTerminal = (e) => {
    setDeparture(e.value);
    setPageLoading(true);
    request(apiroutes.ArrivalTerminals(e.value), "get", null, token)
      .then((res) => {
        setArrivalTerminal(res.data.Object);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setPageLoading(false);
      });
  };

  // useEffect(() => {
  //   const getDepartureTerminalRound = () => {
  //     request(apiroutes.DepartureTerminals(), "get", null, token)
  //       .then((res) => {
  //         setDepartureTerminalRound(res.data.Object.Items);
  //       })
  //       .catch((err) => {
  //         console.log(err.response);
  //       });
  //   };
  //   getDepartureTerminalRound();
  // }, [token]);

  const getArrivalTerminalRound = (e) => {
    setDepartureRound(e.value);
    setPageLoadingRound(true);
    request(apiroutes.ArrivalTerminals(e.value), "get", null, token)
      .then((res) => {
        setArrivalTerminalRound(res.data.Object);
        setPageLoadingRound(false);
      })
      .catch((err) => {
        console.log(err.response);
        setPageLoadingRound(false);
      });
  };

  const handleOneWay = (e) => {
    e.preventDefault();
    const newStartDate = new Date(startDate);
    let date = JSON.stringify(newStartDate);
    date = date.slice(1, 11);
    let adultNo = localStorage.getItem("adults");
    adultNo = JSON.parse(adultNo);
    // console.log(adultNo, "NEW ADULT")

    setLoading(true);
    const bookingData = {
      DepartureTerminalId: departure,
      DestinationTerminalId: arrival,
      DepartureDate: date,
      NumberOfAdults: adult,
      // NumberOfAdults: parseInt(adultNo),
      // NumberOfAdults: adultNo || "1",
      TripType: "0",
      BookingType: "0",
      NumberOfChildren: "0",
    };

    const currItem = optionTerminal;
    // const currItem = JSON.parse(JSON.stringify(departureTerminal));
    const departing = currItem.find((terminal) => terminal.value === departure);

    const currItemArrival = JSON.parse(JSON.stringify(arrivalTerminal));
    const arriving = currItemArrival.find(
      (terminal) => terminal.TerminalId === arrival
    );
    const userSelect = {
      noOfAdult: adult,
      // noOfAdult: parseInt(adultNo),
      // noOfAdult: adultNo || "1",
      date: startDate,
      departureName: departing.label,
      arrivalName: arriving.TerminalName,
    };

    localStorage.setItem("userSelect", JSON.stringify(userSelect));

    request(apiroutes.BookingSearch(), "post", bookingData, token)
      .then((res) => {
        setLoading(false);
        // console.log(res.data, "Error validating your request, Please try again!");
        if (res.data.Object === null) {
          // alert(res.data.ShortDescription);
          Swal.fire({
            showConfirmButton: false,
            timer: 10000,
            text: res.data.ShortDescription,
            // text: `Error validating your request, Please try again!`,
            icon: "error",
          });
          // history.push("/");
          // window.location.reload();
        } else {
          localStorage.setItem(
            "allTripDetails",
            JSON.stringify(res.data.Object)
          );
          history.push("/select-bus");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleRoundWay = (e) => {
    e.preventDefault();
    const newStartDate = new Date(startDateRound);
    let beginDate = JSON.stringify(newStartDate);
    beginDate = beginDate.slice(1, 11);
    const newEndDate = new Date(endDateRound);
    let stopDate = JSON.stringify(newEndDate);
    stopDate = stopDate.slice(1, 11);
    let adultNo = localStorage.getItem("adultsRound");
    adultNo = JSON.parse(adultNo);
    setLoading(true);
    const bookingData = {
      DepartureTerminalId: departureRound,
      DestinationTerminalId: arrivalRound,
      DepartureDate: beginDate,
      ReturnDate: stopDate,
      NumberOfAdults: adultRound,
      // NumberOfAdults: adultNo || "1",
      TripType: "1",
    };
    // console.log(bookingData, 'round trip post')

    const currItem = optionTerminal;
    // const currItem = JSON.parse(JSON.stringify(departureTerminalRound));
    const departing = currItem.find(
      (terminal) => terminal.value === departureRound
      // (terminal) => terminal.TerminalId === departureRound
    );

    const currItemArrival = JSON.parse(JSON.stringify(arrivalTerminalRound));
    const arriving = currItemArrival.find(
      (terminal) => terminal.TerminalId === arrivalRound
    );
    const userSelect = {
      noOfAdult: adultRound,
      // noOfAdult: adultNo || "1",
      date: startDateRound,
      endDate: endDateRound,
      departureName: departing.label,
      // departureName: departing.TerminalName,
      arrivalName: arriving.TerminalName,
    };

    localStorage.setItem("userSelect", JSON.stringify(userSelect));

    request(apiroutes.BookingSearch(), "post", bookingData, token)
      .then((res) => {
        // console.log(res.data, 'round trip')
        setLoading(false);
        if (res.data.Object === null) {
          // alert("Error validating your request, Please try again!");
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Error validating your request, Please try again!`,
            icon: "error",
          });
          history.push("/");
          window.location.reload();
        } else {
          localStorage.setItem(
            "allTripDetails",
            JSON.stringify(res.data.Object)
          );
          history.push("/select-bus");
        }
      })
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
      });
  };

  const setAdultt = (e) => {
    localStorage.setItem("adults", JSON.stringify(e.value));
    setAdult(e.value);
  };
  const setAdulttRound = (e) => {
    localStorage.setItem("adultsRound", JSON.stringify(e.value));
    setAdultRound(e.value);
  };

  return (
    <div className="bookSeat">
      <input id="oneway-book-a-set" type="radio" name="tabs" defaultChecked />
      <label htmlFor="oneway-book-a-set" className="oneway-book-a-set">
        One Way
      </label>

      <input id="roundtrip-book-a-set" type="radio" name="tabs" />
      <label htmlFor="roundtrip-book-a-set" className="roundtrip-book-a-set">
        Round Trip
      </label>
      <br />
      <br />

      <section id="content1">
        <div className="row">
          <div className="col-md-12">
            <label className="label-auth">Travelling From</label>
            <Select
              options={optionTerminal}
              // options={departureSelect}
              handleChange={getArrivalTerminal}
              value={departure}
              styles={colorStyles}
              placeholder="Departure Terminal"
              id="departure"
            />
          </div>
        </div>
        <br />
        {pageLoading ? (
          <div className="text-center">
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <label className="label-auth">Travelling To</label>
              <Select
                options={arrivalSelect}
                handleChange={(e) => {
                  setArrival(e.value);
                  localStorage.setItem("adults", JSON.stringify(1));
                  setAdult(1);
                }}
                value={arrival}
                styles={colorStyles}
                placeholder="Arrival Terminal"
                id="arrival"
              />
            </div>
          </div>
        )}

        <br />
        <div className="row row-grid">
          <div className="col-md-6">
            <label className="label-auth">Departure Date</label>
            <DatePicker
              placeholderText="Select Date..."
              selected={startDate}
              minDate={addDays(new Date(), 1)}
              maxDate={addDays(new Date(), 21)}
              // maxDate={addDays(new Date(), 7)}
              onChange={(date) => setStartDate(date)}
              // readOnly={true}
              disabledKeyboardNavigation={true}
              onChangeRaw={(e) => e.preventDefault()}
            />
          </div>
          <div className="col-md-6">
            <label className="label-auth">Adults</label>
            <Select
              options={adultOptions}
              handleChange={(e) => setAdultt(e)}
              // handleChange={(e) => {setAdult(e.value)
              //   console.log(adultRound, "ADULTTTTTTTT")}}
              // defaultValue={{ label: "1", value: 1 }}
              value={adult}
              styles={colorStyles}
              placeholder="1"
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Button
              text={
                loading ? (
                  <div
                    style={{
                      display: "flex",
                      margin: "0 auto",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "200px",
                      transform: "translateX(-10px)",
                    }}
                  >
                    <Loader dark={false} />{" "}
                    <span
                      style={{
                        display: "inline-block",
                        transform: "translateX(-10px)",
                      }}
                    >
                      Looking for Buses
                    </span>
                  </div>
                ) : (
                  "Proceed"
                )
              }
              handleButtonClick={handleOneWay}
              type="button"
              btnstyle={{
                background: "#E21D00",
                color: "white",
                margin: "20px 0px",
                // fontFamily: "Euclid Circular B",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
              }}
              disabled={!(startDate && departure && arrival && adult)}
            />
          </div>
        </div>
      </section>
      <section id="content2">
        <div className="row">
          <div className="col-md-12">
            <label className="label-auth">Travelling From</label>
            <Select
              options={optionTerminal}
              // options={departureSelectRound}
              handleChange={getArrivalTerminalRound}
              value={departureRound}
              placeholder="Departure Terminal"
              styles={colorStyles}
            />
          </div>
        </div>
        <br />
        {pageLoadingRound ? (
          <div className="text-center">
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <label className="label-auth">Travelling To</label>
              <Select
                options={arrivalSelectRound}
                handleChange={(e) => {
                  setArrivalRound(e.value);
                  localStorage.setItem("adultsRound", JSON.stringify(1));
                  setAdultRound(1);
                }}
                value={arrivalRound}
                placeholder="Arrival Terminal"
                styles={colorStyles}
              />
            </div>
          </div>
        )}

        <br />
        <div className="row row-grid">
          <div className="col-md-6">
            <label className="label-auth">Departure Date</label>
            <DatePicker
              placeholderText="Select Date"
              selected={startDateRound}
              minDate={addDays(new Date(), 1)}
              maxDate={addDays(new Date(), 21)}
              // maxDate={addDays(new Date(), 7)}
              onChange={(date) => setStartDateRound(date)}
              // readOnly={true}
              disabledKeyboardNavigation={true}
              onChangeRaw={(e) => e.preventDefault()}
            />
          </div>
          <div className="col-md-6">
            <label className="label-auth">Arrival Date</label>
            <DatePicker
              placeholderText="Select Date..."
              selected={endDateRound}
              minDate={addDays(new Date(startDateRound), 1)}
              // maxDate={addDays(new Date(), 11)}
              maxDate={addDays(new Date(), 21)}
              onChange={(date) => setEndDateRound(date)}
              // readOnly={true}
              disabledKeyboardNavigation={true}
              onChangeRaw={(e) => e.preventDefault()}
            />
          </div>
        </div>
        <br />
        <div className="row row-grid">
          <div className="col-md-6">
            <label className="label-auth">Adults</label>
            <Select
              options={adultOptionsRound}
              // handleChange={(e) => setAdultRound(e.value)}
              handleChange={(e) => setAdulttRound(e)}
              // defaultValue={{ label: "1", value: 1 }}
              // value={adult}
              value={adultRound}
              styles={colorStyles}
              placeholder="1"
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Button
              text={
                loading ? (
                  <div
                    style={{
                      display: "flex",
                      margin: "0 auto",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "200px",
                      transform: "translateX(-10px)",
                    }}
                  >
                    <Loader dark={false} />{" "}
                    <span
                      style={{
                        display: "inline-block",
                        transform: "translateX(-10px)",
                      }}
                    >
                      Looking for Buses
                    </span>
                  </div>
                ) : (
                  "Proceed"
                )
              }
              handleButtonClick={handleRoundWay}
              type="button"
              btnstyle={{
                background: "#E21D00",
                color: "white",
                margin: "20px 0px",
                // fontFamily: "Euclid Circular B",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
              }}
              disabled={
                !(
                  startDateRound &&
                  departureRound &&
                  arrivalRound &&
                  endDateRound &&
                  adultRound
                )
              }
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default BookSeat;

// //For each element of an array, a counter is set to 0. The element is compared to each element to its left.
// //If the element to the left is greater, the absolute difference is subtracted from the counter.
// // If the element to the left is less, the absolute difference is added to the counter.
// //For each element of the array, determine the value of the counter. These vaues should be stored in an array and returned.
// function arrayChallenge () {
//    const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
//   const result = array.map((element, index) => {
//     let counter = 0;
//     for(let i = 0; i < index; i++) {
//       if(array[i] > element) {
//         counter -= Math.abs(array[i] - element);
//       } else {
//         counter += Math.abs(array[i] - element);
//       }
//     }
//     return counter;
//   });
//   return result;
// }
