const makeAPICallBudPay = async (
    { method = "POST", payload = null },
    ...customConfigs
  ) => {
    const headers = {
      "Accept": "application/json",
      "Content-type": "application/json",
      "Authorization": "Bearer sk_live_sgahjygly2ytbimzy26h1nsn5noxvzsh2riwj5n",
      // "Authorization": "Bearer sk_test_s1x0rwmti5qcrsnhiyy8xfhbip6nybtq8x4qarh",
      "Access-Control-Allow-Origin": "*",
      // "Content-type": "application/x-www-form-urlencoded",
      "Origin": "",
      "X-Requested-With": "",
    };
  
    const configs = {
      method,
      headers,
      ...customConfigs,
    };
  
    if (payload) configs.body = JSON.stringify(payload);
  
    return window
      // .fetch("https://cors-anywhere.herokuapp.com/https://api.flutterwave.com/v3/payments", configs)
      .fetch("https://www.budpay.com/api/v1/transaction/initialize", configs)
      .then((response) => response.json())
      .catch((err) => err);
  };
  
  export default makeAPICallBudPay;