import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/index";
import MobileNavBar from "../MobileNavbar/index";
import Navbar from "../../../components/NavBar/index";
import Details from "./bookingdetails";
import History from "./bookinghistory";
import "./booking.css";
import { request } from "../../../services/apiservice";
import apiroutes from "../../../services/apiroutes";
import { getAuth, getUser } from "../../../services/auth";
import Footer from "../../../components/Footer";

export const BookingHistory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [history, setHistory] = useState(false);
  const [details, setDetails] = useState(false);
  const [bookingInfo, setBookingInfo] = useState();
  const [bookingItem, setBookingItem] = useState();
  const [loader, setLoader] = useState(true);
  const loggedInUser = getUser();
  const token = getAuth("access_token");

  const handleChange = (item) => {
    setLoader(false);
    setHistory(false);
    setDetails(true);
    // console.log(item, "item");
    setBookingItem(item);
    // console.log(loggedInUser + " userrrr");
  };

  const handleDetails = () => {
    setHistory(true);
    setDetails(false);
  };

  useEffect(() => {
    if (loggedInUser === null) {
      history.push("/");
      return false;
    } else {
      bookingToken();
    }
    // eslint-disable-next-line
  }, []);

  const bookingToken = () => {
    request(
      apiroutes.BookingHistory(loggedInUser.PhoneNumber),
      "get",
      null,
      token
    )
      .then((res) => {
        // console.log(res);
        setBookingInfo(res.data.Object);
        setLoader(false);
        setHistory(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(bookingInfo);
  const historyContent = history ? (
    <History onclick={handleChange} product={bookingInfo} />
  ) : null;

  const detailContent = details ? (
    <Details onclick={handleDetails} details={bookingItem} />
  ) : null;
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="dashboardArea">
          <SideBar />
          <div className="contentarea">
            <MobileNavBar />
            {loader ? (
              <div className="text-center">
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <>
                {historyContent}
                {detailContent}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default BookingHistory;
