import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import "./index.css";
import Button from "../../components/Button";
//import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
//import "react-phone-number-input/style.css";
import InputField from "../../components/InputField";
// import Select from "../../../components/Dropdown/index";
import Modal from "../../components/Modal";
import nb from "../../assets/img/nb.png";
import Paystack from "../../assets/img/paystack.png";
// import Flutterwave from "../../assets/img/flutterwave_logo_color.svg";
import BankTransfer from "../../assets/img/bktransfer.png";
import apiroutes from "../../services/apiroutes";
import { useHistory } from "react-router";
import { request } from "../../services/apiservice";
import { getAuth } from "../../services/auth";
import makeAPICall from "../../services/paystackPay";
// import makeAPICallFlutter from "../../services/flutterwavePay";
import { getUser } from "../../services/auth";
import config from "../../configs";

export const HireDetailsComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const loggedInUser = getUser();
  // console.log(loggedInUser)
  const history = useHistory();
  const [modalVisible, setVisible] = useState(false);
  const [value, setValue] = useState();
  const [valueTwo, setValueTwo] = useState("");
  const [fullName, setFullName] = useState("");
  const [kinName, setKinName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [alternativeDate, setAlternativeDates] = useState(new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738)));
  const token = getAuth("access_token");
  const paymentOptions = [
    // { id: 13, name: "woven" },
    { id: 4, name: "bankTransfer" },
    { id: 5, name: "paystack" },
  ];

  let hireDetails = localStorage.getItem("allHireDetails");
  hireDetails = hireDetails === null ||hireDetails === undefined || hireDetails === "" ? history.push("/") : JSON.parse(hireDetails);
  // const hireDetailsFiltered = hireDetails.Departures.filter((e) => e !== null);
  // console.log(hireDetails, "hire details");
  let selectedBusFare = localStorage.getItem("selectedHireBuses");
  selectedBusFare = JSON.parse(selectedBusFare);
  let busNameQantity = localStorage.getItem("busNameQantity");
  busNameQantity = JSON.parse(busNameQantity);
  const selectedBusNameQantityFiltered = busNameQantity?.filter(
    (e) => e !== null
  );

  const travelDistance = localStorage.getItem("estimatedTravelDistance");
  // const returnPickupDate = localStorage.getItem("returnPickupDate");
  let returnPickupDate = localStorage.getItem("returnHireDate");
  returnPickupDate = JSON.parse(returnPickupDate);
  const routeDate = hireDetails?.Departures[0]?.OnewayPickupDate ? new Date(hireDetails.Departures[0].OnewayPickupDate) : alternativeDate;
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // const documentTypeOptions = ["Passport", "Visa"];
  // const optionsDocumentType = documentTypeOptions.map((x) => ({
  //   label: x,
  //   value: x,
  // }));

  //   console.log(selectedBusFare, "all data for selected buses");

  // eslint-disable-next-line no-extend-native
  // Array.prototype.sum = function (prop, propTwo) {
  //   var total = 0;
  //   for (var i = 0; i <= 3; i++) {
  //   // for (var i = 0, _len = this.length; i < _len; i++) {
  //     // if(i === 0) {
  //     //   Array.prototype.pull[i];
  //     // }
  //     total = [i][prop] * [i][propTwo];
  //   }
  //   return total;
  // };

  //   console.log(selectedBusFare.sum("amount", "Quantity"))

  // const totalPayableAmount = selectedBusFare.sum(
  //   "FarePrice",
  //   "NoOfBookedVehicle"
  // );
  const selectedBusFareFiltered = selectedBusFare?.filter((e) => e !== null);
  // console.log(selectedBusFareFiltered);
  let totalPayableAmount = selectedBusFareFiltered?.reduce(
    (a, c) => a + c.FarePrice * c.NoOfBookedVehicle,
    0
  );
  // let totalPayableAmount = selectedBusFareFiltered?.reduce(
  //   (a, c) => a + c.FarePrice + c.SleepOverPrice * c.NoOfBookedVehicle,
  //   0
  // );
  let totalPayableAmountRound = selectedBusFareFiltered?.reduce(
    (a, c) => a + (c.FarePrice * 2) + c.SleepOverPrice * c.NoOfBookedVehicle,
    0
  );
  totalPayableAmount?.toFixed();

  const totalPayableAmountWithCalc =
    hireDetails?.HiredServiceType === 0
      ? totalPayableAmount
      : totalPayableAmountRound;
      // : totalPayableAmount * 2;

  const totalQuantity = selectedBusFareFiltered?.reduce(
    (n, { NoOfBookedVehicle }) => n + NoOfBookedVehicle,
    0
  );

  useEffect(() => {
    if (loggedInUser === null) {
      return false;
    } else {
      setValueTwo(loggedInUser.PhoneNumber);
      setFullName(loggedInUser.FirstName);
      setEmail(loggedInUser.Email);
      setGender(loggedInUser.Gender === 0 ? "male" : "female");
      setKinName(loggedInUser.NextOfKinName);
      setValue(loggedInUser.NextOfKinPhone);
    }
    // eslint-disable-next-line
  }, []);

  const onValueChange = (e) => {
    setGender(e.target.value);
  };


  const  checkEmail = () => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `Please provide a valid email address`,
        icon: "error",
      });
      return false;
    } else {
      toggleModal()
    }
  }


  const toggleModal = () => {
    if (valueTwo === value) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "Passenger and Next of Kin Phone No. should not be the same!!!",
        icon: "error",
      });
    } else if (value.length < 11 || valueTwo.length < 11) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "Phone No. should not be less than 11 characters",
        icon: "error",
      });
    } else if (value.length > 11 || valueTwo.length > 11) {
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: "Phone No. should not be greater than 11 characters",
        icon: "error",
      });
    } else {
      setVisible(true);
    }
    // setLoading(true);
  };

  const toggleModalClose = () => {
    setVisible(false);
  };

  const nf = new Intl.NumberFormat();

  const payPostSearch = (items) => {
    setPageLoading(true);
    const newStartDate = hireDetails?.Departures[0]?.OnewayPickupDate ? new Date(hireDetails?.Departures[0]?.OnewayPickupDate) : alternativeDate;
    let date = JSON.stringify(newStartDate);
    date = date.slice(1, 11);
    let postData;
    if (hireDetails?.HiredServiceType === 0) {
      postData = {
        FullName: fullName,
        Gender: gender === "male" ? 0 : 1,
        Email: email,
        PhoneNumber: valueTwo,
        NextOfKinName: kinName,
        NextOfKinPhone: value,
        Address: address,
        PaymentMethod: items.id,
        HiredServiceType: "0",
        OnewayPickupLocation: hireDetails?.Departures[0]?.OnewayPickupLocation,
        OneWayDropoffLocation: hireDetails?.Departures[0]?.OneWayDropoffLocation,
        OnewayDistanceApart: travelDistance,
        OnewayPickupDate: date,
        IsSleepOver: hireDetails?.Departures[0]?.IsSleepOver,
        HireVehicleDetail: selectedBusFareFiltered,
        // HireVehicleDetail: selectedBusFare,
        Amount: totalPayableAmountWithCalc,
      };
    } else {
      postData = {
        FullName: fullName,
        Gender: gender === "male" ? 0 : 1,
        Email: email,
        PhoneNumber: valueTwo,
        NextOfKinName: kinName,
        NextOfKinPhone: value,
        Address: address,
        PaymentMethod: items.id,
        HiredServiceType: "1",
        OnewayPickupLocation: hireDetails?.Departures[0]?.OnewayPickupLocation,
        OneWayDropoffLocation: hireDetails?.Departures[0]?.OneWayDropoffLocation,
        ReturnPickupLocation: hireDetails?.Departures[0]?.OneWayDropoffLocation,
        ReturnDropoffLocation: hireDetails?.Departures[0]?.OnewayPickupLocation,
        OnewayDistanceApart: travelDistance,
        ReturnDistanceApart: travelDistance,
        OnewayPickupDate: date,
        ReturnPickupDate: returnPickupDate,
        IsSleepOver: hireDetails.Departures[0].IsSleepOver,
        HireVehicleDetail: selectedBusFareFiltered,
        // HireVehicleDetail: selectedBusFare,
        Amount: totalPayableAmountWithCalc,
      };
    }
    //   console.log(postData, "post Data info return");

    request(apiroutes.HireServiceSearch(), "post", postData, token)
      .then((res) => {
        // console.log(res, "Here are the sleep over details");
        // console.log(
        //   res.data.Object.Amount,
        //   "Here is the amount of trip details"
        // );
        const emailAmount = {
          email: email,
          amount: totalPayableAmountWithCalc,
          // amount: res.data.Object.Amount,
          mainName: fullName,
          date: date,
          returnDate: returnPickupDate,
          refCode: res.data.Object.RefCode,
        };
        // console.log(res.data.Object.RefCode);
        localStorage.setItem("hireEmailAmount", JSON.stringify(emailAmount));
        if (items.id === 5) {
          paystackPay(res.data.Object.RefCode, res.data.Object.Amount);
        } else if (items.id === 4) {
          // flutterwavePay(res.data.Object.RefCode, res.data.Object.Amount);
          // localStorage.setitem("bankTransferDetails", res.data.Object )
          history.push("/bankTransferPayment");
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  const paystackPay = (refCode, amount) => {
    const configLoad = {
      reference: refCode,
      email: email,
      amount: amount * 100,
      callback_url: config.FALL_BACK_ENDPOINT_HIRE,
    };
    return makeAPICall({
      payload: configLoad,
      method: "POST",
    })
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          window.location.href = `https://checkout.paystack.com/${result.data.access_code}`;
        }
      })
      .catch((err) => console.log(err));
  };

  // const flutterwavePay = (refCode, amount) => {
  //   console.log( refCode, amount, "flutterwave pay");
  //   // return
  //   const configLoad = {
  //     tx_ref: refCode,
  //     amount: amount * 100,
  //     currency: "NGN",
  //     redirect_url: config.FALL_BACK_ENDPOINT_HIRE,
  //     payment_options: "card, mobilemoney, ussd",
  //     customer: {
  //       email: email,
  //       phonenumber: valueTwo,
  //     },
  //   };
  //   return makeAPICallFlutter({
  //     payload: configLoad,
  //     method: "POST",
  //   })
  //     .then((result) => {
  //       console.log(result, "flutterwave works");
  //       if (result.status === "success") {
  //         window.location.href = result.data.link;
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  const width = 500;
  const height = 530;
  const modalTitle = "Trip payment";
  const modalPar = "You are about to fund this trip";
  const modalBody = (
    <div>
      <div className="text-center">
        <p className="passenger-amt">Amount</p>
        <h1 className="p-amt">₦{nf.format(totalPayableAmountWithCalc)}</h1>
        <p className="p-nb">
          <img src={nb} alt="" style={{ width: "10px" }} /> Selecting any of the
          payment method will redirect you to their payment platform.
        </p>
        <h2 className="pay-mthod">Select payment method</h2>
      </div>
      <br />
      <div className="row">
        <div className="col-sm-12">
          {pageLoading ? (
            <div className="text-center">
              <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className="payee payment">
              {paymentOptions.map((items) => (
                // <div className="col-md-4" key={items.id}>
                <div className="pay-bg-hire" key={items.id}>
                  <div className="payment-hire">
                    {items.id === 5 ? (
                      <img
                        onClick={() => payPostSearch(items)}
                        src={Paystack}
                        alt="paystack"
                      />
                    ) : items.id === 4 ? (
                      <img
                        onClick={() => payPostSearch(items)}
                        src={BankTransfer}
                        alt="bakTransferPayment"
                      />
                    ) : (
                      <>{/* <div style={{ display: "none" }}></div> */}</>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Navbar />
      <Modal
        width={width}
        height={height}
        visible={modalVisible}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBody}
        handleClose={toggleModalClose}
      />
      <section className="passenger-details pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="h1-route">
                Hi {loggedInUser === null ? "Guest" : loggedInUser.FirstName},
                We just need a few more details. Who is travelling?
              </h1>
            </div>
          </div>
          <br />
          <div className="row row-grid sticky">
            <div className="col-md-6">
              <div className="mobileShow">
                <div className="accordion-item-test">
                  <div
                    className="accordion-title-test"
                    onClick={() => setIsActive(!isActive)}
                  >
                    <div>
                      <h1 className="trip-route">Trip Summary</h1>
                    </div>
                    <div className="actve-state">
                      {isActive ? "see less" : "see more"}
                    </div>
                  </div>
                  {isActive && (
                    <div className="accordion-content-test">
                      <div className="trip-summary">
                        <div className="d-flex">
                          <p className="left-par">From </p>
                          <p className="left-par ml-auto text-right">
                            <span>
                              {hireDetails.Departures[0].OnewayPickupLocation}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="left-par">To </p>
                          <p className="left-par ml-auto">
                            <span>
                              {hireDetails.Departures[0].OneWayDropoffLocation}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="left-par">Date </p>
                          <p className="left-par ml-auto">
                            <span>{dateTimeFormat.format(routeDate)}</span>{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="left-par">Vehicle Type</p>
                          <p className="left-par ml-auto">
                            <span>
                              {" "}
                              {busNameQantity
                                ?.map((item) => item.busName)
                                .join(", ")}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="left-par">Total Number Of Vehicles</p>
                          <p className="left-par ml-auto">
                            <span>{totalQuantity}</span>{" "}
                          </p>
                        </div>
                        <div className="d-flex">
                          <p className="left-par">Total Amount</p>
                          <p className="left-par ml-auto">
                            <span style={{ fontSize: "24px" }}>
                              ₦{nf.format(totalPayableAmountWithCalc)}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <ol className="timeline">
                <li>
                  {" "}
                  <section>
                    <h1 className="step-h1">Passenger Details</h1>

                    <p className="step-p">
                      Please enter name as they appear on identification
                      document
                    </p>
                    <div className="stepform-card">
                      <h2 className="step-p">Adult 1</h2>
                      <label className="label-auth">Full Name</label>
                      <InputField
                        type="text"
                        placeholder="Enter Full Name"
                        onChangeMethod={(e) => setFullName(e.target.value)}
                        value={fullName}
                        readonly={loggedInUser === null ? false : true}
                      />
                      <br />
                      <br />
                      <label className="con1">
                        <span>Male</span>
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          checked={gender === "male"}
                          onChange={onValueChange}
                          disabled={loggedInUser === null ? false : true}
                        />
                        <span className="checkmarkSpan"></span>
                      </label>
                      <label className="con1">
                        <span>Female</span>
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          checked={gender === "female"}
                          onChange={onValueChange}
                          disabled={loggedInUser === null ? false : true}
                        />
                        <span className="checkmarkSpan"></span>
                      </label>
                      <br />
                      <br />
                      <label className="label-auth">Residential Address</label>
                      <textarea
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="text-area"
                      />
                    </div>
                  </section>{" "}
                </li>
                <li>
                  {" "}
                  <section>
                    <h1 className="step-h1">
                      {" "}
                      Contact and Next of Kin Details
                    </h1>
                    <p className="step-p">
                      We need your contact details for booking confirmation
                    </p>
                    <div className="row row-grid">
                      <div className="col-md-6">
                        <label className="label-auth">
                          Customer Email Address
                        </label>
                        <InputField
                          type="email"
                          placeholder="Enter Customer Email"
                          onChangeMethod={(e) => setEmail(e.target.value)}
                          value={email}
                          required
                          readonly={loggedInUser === null ? false : true}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="label-auth">
                          Customer Phone Number
                        </label>
                        {/* <PhoneInput
                          placeholder="Enter Customer phone number"
                          country="NG"
                          value={valueTwo}
                          onChange={setValueTwo}
                        /> */}
                        <InputField
                          type="tel"
                          placeholder="Enter customer number"
                          onChangeMethod={(e) => setValueTwo(e.target.value)}
                          value={valueTwo}
                          readonly={loggedInUser === null ? false : true}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row row-grid">
                      <div className="col-md-6">
                        <label className="label-auth">Next of Kin Name</label>
                        <InputField
                          type="text"
                          placeholder="Enter Next of Kin name"
                          onChangeMethod={(e) => setKinName(e.target.value)}
                          value={kinName}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="label-auth">
                          Next of Kin's Mobile Phone Number
                        </label>
                        {/* <PhoneInput
                          placeholder="Enter next of kin number"
                          country="NG"
                          value={value}
                          onChange={setValue}
                        /> */}
                        <InputField
                          type="tel"
                          placeholder="Enter next of kin number"
                          onChangeMethod={(e) => setValue(e.target.value)}
                          value={value}
                        />
                      </div>
                    </div>
                  </section>
                </li>
                <li>
                  <section>
                    <Link to="/terms" target="_blank">
                      <h1 className="step-h1">Terms and Condition</h1>
                    </Link>
                    <p className="step-P">
                      Please Note that GIGM does not have a refund policy,
                      However, our tickets valid for a Month. By proceeding to
                      Make Payment, You agree to the Terms and conditions of
                      GIGM
                    </p>
                  </section>
                  <br />
                  <div className="mobileShow">
                    <Button
                      text="Pay"
                      handleButtonClick={checkEmail}
                      type="button"
                      btnstyle={{ backgroundColor: " #E21D00" }}
                      disabled={
                        !(
                          fullName &&
                          gender &&
                          kinName &&
                          value &&
                          valueTwo &&
                          email &&
                          address
                        )
                      }
                    />
                  </div>
                </li>
              </ol>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4 mobileHide con-sticky">
              <div className="trip-summary">
                <h1 className="trip-route text-center">Trip Summary</h1>
                <div className="d-flex">
                  <p className="left-par">From </p>
                  <p className="left-par ml-auto text-right">
                    <span>
                      {hireDetails?.Departures[0]?.OnewayPickupLocation}
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="left-par">To </p>
                  <p className="left-par ml-auto text-right">
                    <span>
                      {hireDetails?.Departures[0]?.OneWayDropoffLocation}
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="left-par">Date </p>
                  <p className="left-par ml-auto text-right">
                    <span>{dateTimeFormat.format(routeDate)}</span>{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="left-par">Vehicle Type</p>
                  <p className="left-par ml-auto text-right">
                    <span>
                      {" "}
                      {selectedBusNameQantityFiltered?.map((item) => item.busName).join(", ")}
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="left-par">Total Number Of Vehicles</p>
                  <p className="left-par ml-auto text-right">
                    <span>{totalQuantity}</span>{" "}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="left-par">Total Amount</p>
                  <p className="left-par ml-auto text-right">
                    <span style={{ fontSize: "24px" }}>
                      ₦{nf.format(totalPayableAmountWithCalc)}
                    </span>{" "}
                  </p>
                </div>
                <Button
                  text="Pay"
                  handleButtonClick={toggleModal}
                  type="button"
                  btnstyle={{ backgroundColor: " #E21D00" }}
                  disabled={
                    !(
                      fullName &&
                      gender &&
                      kinName &&
                      value &&
                      valueTwo &&
                      email &&
                      address
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default HireDetailsComponent;
