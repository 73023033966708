import React, { useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Alert from "../../../components/Alert";
import Button from "../../../components/Button/index";
import Select from "../../../components/Dropdown/index";
import Expire from "../../../components/Expire";
import Loader from "../../../components/Loader";
import apiroutes from "../../../services/apiroutes";
import { request } from "../../../services/apiservice";

const Submitform = ({ prev }) => {
  const history = useHistory();
  const [year, setYear] = useState("");
  const [maker, setMaker] = useState("");
  const [model, setModel] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  let individualPartnerDetails = localStorage.getItem(
    "individualPartner_Details"
  );
  individualPartnerDetails = JSON.parse(individualPartnerDetails);

  const makerOptions = ["JET", "Toyota"];
  const optionMaker = makerOptions.map((x) => ({ label: x, value: x }));
  const modelOptions = ["JET Movers", "Hiace (High Roof)"];
  const optionModel = modelOptions.map((x) => ({ label: x, value: x }));
  const colorOptions = [
    "Silver",
    "Wine",
    "Navy",
    "Blue",
    "White",
    "Black",
    "Champagne",
    "Gold",
  ];
  const optionColor = colorOptions.map((x) => ({ label: x, value: x }));

  let years = [];
  const d = new Date();
  const n = d.getFullYear();
  for (let i = 2013; i <= n; i++) {
    years.push(i);
  }

  const optionYear = years.map((x) => ({ label: x, value: x }));

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setResponseMsg("Processing");
    setLoading(true);
    const individualPartnersInformation = {
      Vehicles: [
        {
          Make: maker,
          VehicleMake: year,
          VehicleModel: model,
          Colour: color,
        },
      ],
      Name: individualPartnerDetails.firstName + " " + individualPartnerDetails.lastName,
      BusinessName: "Individual",
      CompanyRcNumber: "Individual",
      InspectionLocation: individualPartnerDetails.InspectionLocation,
      businessAddress: individualPartnerDetails.address,
      State: individualPartnerDetails.state,
      FirstName: individualPartnerDetails.firstName,
      LastName: individualPartnerDetails.lastName,
      Email: individualPartnerDetails.email,
      PhoneNumber: individualPartnerDetails.phone,
      CompanyName: "individual",
      PartnerType: individualPartnerDetails.partnerType,
      NumberOfVehicles: individualPartnerDetails.NumberOfVehicles,
      CountryCodes: "NG",
    };
    // console.log(
    //   individualPartnersInformation,
    //   "EP INFOOOOOOOOOOOOOOOOOOOOOOOOOOO"
    // );
    let authDetails = localStorage.getItem("partnerAuthDetails");
    authDetails = JSON.parse(authDetails);
    const token = authDetails.access_token;
    request(
      apiroutes.SavePartnerEnquiry(),
      "post",
      individualPartnersInformation,
      token
    )
      .then((res) => {
        // console.log(res);
        if (res.data.Object === null) {
          alert(res.data.ShortDescription)
          localStorage.removeItem("cooperatePartner_Details");
          setLoading(false);
          setResponseMsg("")
        } else {
        setLoading(false);
        setResponseMsg(
          "Thanks for your enquiry! We would get back to you within the next 48 hours"
        );
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: "Thanks for your enquiry! We would get back to you within the next 48 hours",
          icon: "success",
        });
        setError(false);
        localStorage.removeItem("individualPartner_Details");
        setTimeout(() => {
          history.push("/");
        }, 4000);
      }
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
          Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: err.message,
          icon: "error",
        });
        setResponseMsg(null);
      });
  };
  return (
    <div>
      <section>
        <div className="contactform">
          <div className="contactformholder">
            <div className="partnerform">
              <h3 class="backicon" onClick={prev}>
                <i class="fa fa-arrow-left"></i>&nbsp; &nbsp;Back
              </h3>
              <h1>Vehicle Details</h1>
              <p>Kindly provide all the details below</p>
              <div className="col-12 justify-content-md-center">
                {error && (
                  <Expire delay={5000}>
                    <Alert
                      className="alert text-center alert-danger"
                      text={error}
                    />
                  </Expire>
                )}
                {responseMsg && !error && (
                  <Expire delay={5000}>
                    <Alert
                      className="alert text-center alert-primary"
                      text={responseMsg}
                    />
                  </Expire>
                )}
              </div>

              <div>
                <label htmlFor="state">Select Maker</label>
                <Select
                  value={maker}
                  options={optionMaker}
                  handleChange={(e) => setMaker(e.value)}
                  placeholder="Select maker"
                  styles={colorStyles}
                />
                <label htmlFor="state">Select Model</label>
                <Select
                  value={model}
                  options={optionModel}
                  handleChange={(e) => setModel(e.value)}
                  placeholder="Select model"
                  styles={colorStyles}
                />
                <label htmlFor="state">Select Colour</label>
                <Select
                  value={color}
                  options={optionColor}
                  handleChange={(e) => setColor(e.value)}
                  placeholder="Select color"
                  styles={colorStyles}
                />
                <label htmlFor="state">Select Year</label>
                <Select
                  value={year}
                  options={optionYear}
                  handleChange={(e) => setYear(e.value)}
                  placeholder="Select year"
                  styles={colorStyles}
                />
                <Button
                  text={
                    loading ? (
                      <div>
                        <Loader dark={false} />{" "}
                      </div>
                    ) : (
                      "Submit"
                    )
                  }
                  btnstyle={{ backgroundColor: "#E21D00", margin: "20px 0px" }}
                  disabled={!(year && color && model && maker)}
                  handleButtonClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Submitform;
