import { convertDistance, getDistance } from "geolib";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import circleIcon from "../../assets/img/tick-circle.png";
import errorIcon from "../../assets/img/x-circle.png";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import Navbar from "../../components/NavBar";
import { formatCurrency } from "../../components/util";
import apiroutes from "../../services/apiroutes";
import { request } from "../../services/apiservice";
import { getAuth } from "../../services/auth";
import "./index.css";

export const BookingStatusComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // setUserDepartureTerminal(bookingData?.DepartureTerminald);
    // getTerminalById();
    // setTimeout(()=> getDistance(), [3000])
    setTimeout(() => getTerminalById(), [3000]);
  }, []);
  const history = useHistory();
  const token = getAuth("access_token");
  const [loading, setLoading] = useState(false);
  const [userLat, setUserLat] = useState("");
  const [userLng, setUserLng] = useState("");
  // const [userDepartureTerminal, setUserDepartureTerminal] = useState("");
  const [userDepartureTerminalLat, setUserDepartureTerminalLat] = useState("");
  const [userDepartureTerminalLng, setUserDepartureTerminalLng] = useState("");
  const [checkinError, setCheckinError] = useState(false);
  const [checkinSuccess, setCheckinSuccess] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alternativeDate, setAlternativeDates] = useState(
    new Date(Date.UTC(2020, 11, 20, 3, 23, 16, 738))
  );

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else if (!navigator.geolocation) {
      // alert("You must grant location access to Check In!!!");
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `You must grant location access to Check In.`,
        icon: "error",
      });
      window.location.reload();
    } else {
      // alert("You must grant location access to Check In!!!");
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `You must grant location access to Check In.`,
        icon: "error",
      });
      window.location.reload();
    }
  };

  const showPosition = (position) => {
    // alert("Latitude: " + position.coords.latitude +
    // "<br>Longitude: " + position.coords.longitude);
    setUserLat(position.coords.latitude);
    setUserLng(position.coords.longitude);
    const distance = getDistance(
      { latitude: userLat, longitude: userLng },
      {
        latitude: userDepartureTerminalLat,
        longitude: userDepartureTerminalLng,
      }
    );
    // console.log(convertDistance(distance, "km"), "HERE IS THE DISTANCEEEE");

    if (convertDistance(distance, "km") < 300) {
      // alert("YOU ARE CHECKED IN");
      handleCheckIn();
    } else {
      // alert("YOU MUST BE LESS THAN 300KM TO THE TERMINAL TO CHECKED IN");
      Swal.fire({
        showConfirmButton: false,
        timer: 4000,
        text: `YOU MUST BE LESS THAN 300KM TO THE TERMINAL TO CHECKED IN.`,
        icon: "error",
      });
    }
  };

  const handleCheckIn = () => {
    // setError("");
    // setResponseMsg("Processing");
    setLoading(true);
    // const verify = {
    //   UserName: phoneNumber,
    // };
    // request(apiroutes.CheckPassengerIn("OH-909080000", "29"), "get", null, token)
    request(
      apiroutes.CheckPassengerIn(
        bookingData[0]?.refCode,
        bookingData[0]?.DepartureTerminald
      ),
      "get",
      null,
      token
    )
      .then((res) => {
        // console.log(res.data, 'CHECKINGGGGGGGGGGGGGGGGGGGGGGGG');
        if (
          res.data.Code === null ||
          res.data.Object === null ||
          res.data.Object === null
        ) {
          setLoading(false);
          setErrorMessage(res.data.ShortDescription);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `${res.data.ShortDescription}`,
            icon: "error",
          });
          // alert("ERROR WHILE TRYING TO CHECK YOU IN, PLEASE TRY AGAIN!!!")
          // setError(res.data.ShortDescription);
          setCheckinError(true);
          // setTimeout(() => setCheckinError(true), 1500)
        } else {
          setLoading(false);
          setCheckinSuccess(true);
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `YOU ARE CHECKED IN!!!`,
            icon: "success",
          });
          // alert("YOU ARE CHECKED IN!!!");
          setTimeout(() => history.push("/"), 1500);
        }
      })
      .catch((err) => {
        // setResponseMsg(null);
        setLoading(false);
        setCheckinError(true);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `ERROR WHILE TRYING TO CHECK YOU IN, PLEASE TRY AGAIN!!!`,
          icon: "error",
        });
        // alert("ERROR WHILE TRYING TO CHECK YOU IN, PLEASE TRY AGAIN!!!");
        setTimeout(() => window.location.reload(), 1500);
        // window.location.reload();
      });
  };

  const getTerminalById = () => {
    // request(apiroutes.GetTerminalById(29), "get", null, token)
    request(
      apiroutes.GetTerminalById(parseInt(bookingData[0]?.DepartureTerminald)),
      "get",
      null,
      token
    )
      .then((res) => {
        // console.log(res.data, "BRANDSSSSSSSSSSSSSSSSSSSSS");
        setUserDepartureTerminalLat(res.data.Object.Latitude);
        setUserDepartureTerminalLng(res.data.Object.Longitude);
      })
      .catch((err) => {
        // alert(err);
        Swal.fire({
          showConfirmButton: false,
          timer: 4000,
          text: `${err}`,
          icon: "error",
        });
        // console.log(err, "ERRRRRRRRRRRRRRRRRRR");
      });
  };

  //   const distance = getDistance(
  //     { latitude: 6.5437696, longitude: 3.3488896 },
  //     { latitude: 6.518806, longitude: 3.367547 }
  // )

  // console.log(distance, "HERE IS THE DISTANCEEEE")
  // console.log(convertDistance(distance, 'km'), "HERE IS THE DISTANCEEEE")

  // const getDistance = (e) => {
  //   e.preventDefault();
  //   // setLoading(true);

  //   // initialize services
  //   const origin = [6.5437696, 3.3488896];
  //   const destination = [6.518806, 3.367547];
  //   // const service = new window.google.maps.DistanceMatrixService();
  //   const requestInfo = {
  //     origins: [origin],
  //     destinations: [destination],
  //     travelMode: "DRIVING",
  //     unitSystem: window.google.maps.UnitSystem.METRIC,
  //     avoidHighways: false,
  //     avoidTolls: false,
  //   };

  //   service.getDistanceMatrix(requestInfo, callback);

  //   function callback(response, status) {
  //     console.log(response, status);
  //     let distance = response.rows[0].elements[0].distance.text.split("km");
  //     console.log(distance, "test");
  //     const details = {
  //       OnewayDistanceApart: parseInt(distance[0]),
  //     };
  //   }
  // };

  let bookingData = localStorage.getItem("allBookingStatus");
  bookingData = JSON.parse(bookingData);

  // let ifTripIsReturn = bookingData.map((item) => item.HasReturn  )
  // ifTripIsReturn = ifTripIsReturn.includes(Boolean(true))
  // console.log(ifTripIsReturn, "returning")
  const dateCreated = bookingData[0]?.DateCreated
    ? new Date(bookingData[0]?.DateCreated)
    : alternativeDate;
  const dateDeparture = bookingData[0]?.DepartureDate
    ? new Date(bookingData[0]?.DepartureDate)
    : alternativeDate;

  // let setNumbers = localStorage.getItem("selectedSeats");
  // setNumbers = JSON.parse(setNumbers);
  // console.log(bookingData.DepartureTerminald, "DepartureTerminald");

  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  //   const nf = new Intl.NumberFormat();

  const toggleCheckinErrorClose = () => {
    setCheckinError(false);
  };
  const toggleCheckinSuccessClose = () => {
    setCheckinError(false);
  };
  const toggleticketModalClose = () => {
    setTicketModal(false);
  };

  const width = 450;
  const heightSe = 380;
  const widthh = 500;
  const heightt = 550;
  const modalTitle = "Bus Ticket";

  const modalBodyError = (
    <div className="row">
      <div className="col-md-12">
        <div className="text-center">
          <img src={errorIcon} alt="" width="70" height="70" />
        </div>
        <p
          className="wallet-onboarding-p text-center"
          style={{ fontWeight: "bold", fontSize: "1.6rem", margin: "20px 0" }}
        >
          Check-In Failed
        </p>
        {errorMessage === "" ? (
          <>
            <p className="wallet-onboarding-p text-center">
              We could not check you in because it's almost departure time.
            </p>
            <p className="wallet-onboarding-p text-center">
              Please go to the counter for support.
            </p>
          </>
        ) : (
          <p className="wallet-onboarding-p text-center">{errorMessage}.</p>
        )}
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-8 offset-md-2">
            <div className="text-center">
              <Button
                type="button"
                handleButtonClick={toggleCheckinErrorClose}
                text="Close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const modalBodySuccess = (
    <div className="row">
      <div className="col-md-12">
        <div className="text-center">
          <img src={circleIcon} alt="" width="70" height="70" />
        </div>
        <p
          className="wallet-onboarding-p text-center"
          style={{ fontWeight: "bold", fontSize: "1.6rem", margin: "20px 0" }}
        >
          Check-In Successful
        </p>

        <p className="wallet-onboarding-p text-center">
          Enjoy your trip and thanks for choosing us.
        </p>
        <p className="wallet-onboarding-p text-center">
          A copy of this ticket has been sent to your email.
        </p>
        <br />
        <div className="row">
          <div className="col-sm-12 col-md-8 offset-md-2">
            <div className="text-center">
              <Button
                type="button"
                handleButtonClick={() => setTicketModal(true)}
                text="View Ticket"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const modalTicketBody = (
    <div className="pt-2 pb-2">
      <div className="">
        <div className="col-sm-12 col-md-12 ">
          <div className="check-in-ticket" style={{ padding: "0 20px" }}>
            <div className="ticket-qr mb-1">
              <div className="qr-bg">
                <QRCode
                  value={bookingData[0]?.BookingReferenceCode}
                  size="150"
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Departure:</p>
              <p className="">
                <strong>{bookingData[0]?.DepartureTerminalName}</strong>
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Destination:</p>
              <p className="">
                <strong>{bookingData[0]?.DestinationTerminalName}</strong>
              </p>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Booking Date:</p>
              <p className="">
                <strong>{dateTimeFormat.format(dateCreated)}</strong>{" "}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Departure Date:</p>
              <p className="">
                <strong>{dateTimeFormat.format(dateDeparture)}</strong>
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>RefCode:</p>
              <p>
                <strong>{bookingData[0]?.BookingReferenceCode}</strong>
              </p>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Amount Paid:</p>
              <p className="">
                <strong>
                  ₦
                  {bookingData[0].Amount === null
                    ? 0
                    : formatCurrency(bookingData[0]?.Amount)}
                </strong>{" "}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Booked Seat:</p>
              <p className="">
                <strong>
                  {bookingData.map((item) => item.SeatNumber.toString())}
                </strong>
                {/* <strong>{bookingData[0]?.SeatNumber}</strong> */}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              {/* <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Bus Type:</p> */}
              <p className="desc-para">
                {/* <strong>{bookingData?.SeatNumber}</strong> */}
              </p>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ padding: "15px 0" }}
            >
              {/* <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Trip Type:</p> */}
              <p className="desc-para">
                {/* <strong>{bookingData?.SeatNumber}</strong>  */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Navbar />
      <Modal
        width={width}
        height={heightSe}
        visible={checkinError}
        body={modalBodyError}
        handleClose={toggleCheckinErrorClose}
      />
      <Modal
        width={width}
        height={heightSe}
        visible={checkinSuccess}
        body={modalBodySuccess}
        handleClose={toggleCheckinSuccessClose}
      />
      <Modal
        width={widthh}
        height={heightt}
        visible={ticketModal}
        title={modalTitle}
        body={modalTicketBody}
        handleClose={toggleticketModalClose}
      />
      <section className="">
        <div className="container h-100">
          <h5 style={{ fontWeight: "bold", paddingTop: "150px" }}>
            Booking Details
          </h5>
          {userDepartureTerminalLat === 0 ? (
            ""
          ) : (
            // {bookingData[0]?.Latitude === 0 ? "" :
            <div className="">
              {bookingData[0]?.IsPrinted === false ? (
                <Button
                  text={loading ? <Loader dark={false} /> : "Check In"}
                  handleButtonClick={() => getLocation()}
                  type="button"
                  btnstyle={{
                    background: "red",
                    color: "white",
                    margin: "20px 0px",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    width: "200px",
                  }}
                  // disabled={!refCode}
                />
              ) : (
                <Button
                  text={loading ? <Loader dark={false} /> : "View Ticket"}
                  handleButtonClick={() => setTicketModal(true)}
                  type="button"
                  btnstyle={{
                    background: "red",
                    color: "white",
                    margin: "20px 0px",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    width: "200px",
                  }}
                  // disabled={!refCode}
                />
              )}
            </div>
          )}
          {bookingData.length === 1 ? (
            <div className="bookingStatusDetails h-100">
              <div className="book-det">
                <div className="info-det">
                  <label className="det-label">Date of Booking</label>
                  <p>
                    {/* <strong>{bookingData.DateCreated}</strong> */}
                    <strong>
                      {dateTimeFormat.format(dateCreated)}
                      {/* {dateTimeFormat.format(new Date(bookingData[0]?.DateCreated))} */}
                    </strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Booking Status</label>
                  <p>
                    <strong>
                      {bookingData[0]?.BookingStatus === 0 ? (
                        <p className="blink_me" style={{ color: "red" }}>
                          Pending
                        </p>
                      ) : bookingData[0]?.BookingStatus === 1 ? (
                        <p style={{ color: "#6AC28F" }}>Confirmed</p>
                      ) : (
                        <p style={{ color: "red" }}>Cancelled</p>
                      )}
                    </strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">No. of Tickets</label>
                  <p>
                    <strong>{bookingData.length}</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Seat Status</label>
                  <p>
                    <strong>Booked</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Amount</label>
                  <p>
                    <strong>
                      ₦
                      {bookingData.Amount === null
                        ? 0
                        : formatCurrency(
                            bookingData[0]?.Amount * bookingData.length
                          )}
                    </strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Route</label>
                  <p>
                    <strong>{bookingData[0]?.RouteName}</strong>
                  </p>
                </div>
              </div>

              <h3 className="traveller-header">Main Traveller Details:</h3>
              <div className="book-det">
                <div className="info-det">
                  <label className="det-label">Full Name</label>
                  <p>
                    <strong>{bookingData[0]?.FullName}</strong>
                  </p>
                </div>
                <div className="info-det">
                  <label className="det-label">Date and Time</label>
                  <p>
                    {/* <strong>{bookingData.DepartureDate} <br/> {bookingData.DepartureTime}</strong> */}
                    <strong>
                      {dateTimeFormat.format(dateDeparture)} <br />{" "}
                      {bookingData[0].DepartureTime}
                    </strong>
                  </p>
                </div>

                <div className="info-det">
                  <label className="det-label">Ref Code</label>
                  <p>
                    <strong>{bookingData[0]?.refCode}</strong>
                  </p>
                </div>

                <div className="info-det">
                  <label className="det-label">Seat Number(s)</label>
                  <p>
                    {/* <strong>{setNumbers}</strong> */}
                    <strong>
                      {/* {bookingData.map((item) => item.SeatNumber.toString() + ", " )} */}
                      {bookingData.length > 1
                        ? bookingData.map(
                            (item) => item.SeatNumber.toString() + ", "
                          )
                        : bookingData.map((item) => item.SeatNumber.toString())}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="book-det">
                <div className="info-det">
                  <label className="det-label">Sex</label>
                  <p>
                    <strong>
                      {bookingData[0]?.Gender === 0 ? "Male" : "Female"}
                    </strong>
                  </p>
                </div>
                {/* <div className="info-det">
                <label className="det-label">Vehicle Details</label>
                <p>
                  <strong>{bookingData[0]?.VehicleRegistrationNumber}</strong>
                </p>
              </div> */}
                <div className="info-det">
                  <label className="det-label">Phone Number</label>
                  <p>
                    <strong>{bookingData[0]?.PhoneNumber}</strong>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            bookingData.map((bookingData) => (
              <div className="bookingStatusDetails h-100">
                {bookingData?.HasReturn === true ? (
                  <h3 className="traveller-header">Departure Trip Details:</h3>
                ) : (
                  <h3 className="traveller-header">Returning Trip Details:</h3>
                )}
                <div className="book-det">
                  <div className="info-det">
                    <label className="det-label">Date of Booking</label>
                    <p>
                      {/* <strong>{bookingData.DateCreated}</strong> */}
                      <strong>
                        {dateTimeFormat.format(
                          new Date(bookingData?.DateCreated)
                        )}
                      </strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Booking Status</label>
                    <p>
                      <strong>
                        {bookingData?.BookingStatus === 0 ? (
                          <p className="blink_me" style={{ color: "red" }}>
                            Pending
                          </p>
                        ) : bookingData?.BookingStatus === 1 ? (
                          <p style={{ color: "#6AC28F" }}>Confirmed</p>
                        ) : (
                          <p style={{ color: "red" }}>Cancelled</p>
                        )}
                      </strong>
                    </p>
                  </div>
                  {/* <div className="info-det">
                  <label className="det-label">No. of Tickets</label>
                  <p>
                    <strong>{bookingData.length}</strong>
                  </p>
                </div> */}
                  <div className="info-det">
                    <label className="det-label">Seat Status</label>
                    <p>
                      <strong>Booked</strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Amount</label>
                    <p>
                      <strong>
                        ₦
                        {bookingData.Amount === null
                          ? 0
                          : formatCurrency(bookingData?.Amount)}
                      </strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Route</label>
                    <p>
                      <strong>{bookingData?.RouteName}</strong>
                    </p>
                  </div>
                </div>

                <h3 className="traveller-header">Main Traveller Details:</h3>
                <div className="book-det">
                  <div className="info-det">
                    <label className="det-label">Full Name</label>
                    <p>
                      <strong>{bookingData?.FullName}</strong>
                    </p>
                  </div>
                  <div className="info-det">
                    <label className="det-label">Date and Time</label>
                    <p>
                      {/* <strong>{bookingData.DepartureDate} <br/> {bookingData.DepartureTime}</strong> */}
                      <strong>
                        {dateTimeFormat.format(
                          new Date(bookingData?.DepartureDate)
                        )}{" "}
                        <br /> {bookingData.DepartureTime}
                      </strong>
                    </p>
                  </div>

                  <div className="info-det">
                    <label className="det-label">Ref Code</label>
                    <p>
                      <strong>{bookingData?.refCode}</strong>
                    </p>
                  </div>

                  <div className="info-det">
                    <label className="det-label">Seat Number(s)</label>
                    <p>
                      <strong>
                        {bookingData.SeatNumber}
                        {/* {bookingData.length > 1 ?
                      bookingData.map((item) => item.SeatNumber.toString() + ", " )
                      : 
                       bookingData.map((item) => item.SeatNumber.toString() )} */}
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="book-det">
                  <div className="info-det">
                    <label className="det-label">Sex</label>
                    <p>
                      <strong>
                        {bookingData?.Gender === 0 ? "Male" : "Female"}
                      </strong>
                    </p>
                  </div>
                  {/* <div className="info-det">
                  <label className="det-label">Vehicle Details</label>
                  <p>
                    <strong>{bookingData[0]?.VehicleRegistrationNumber}</strong>
                  </p>
                </div> */}
                  <div className="info-det">
                    <label className="det-label">Phone Number</label>
                    <p>
                      <strong>{bookingData?.PhoneNumber}</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default BookingStatusComponent;
