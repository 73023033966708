import React, { useState, useEffect } from "react";
import "./payPaystack.css";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
// import InputField from "../../components/InputField";
// import Loader from "../../components/Loader";
import { useHistory } from "react-router";
import { PaystackConsumer } from "react-paystack";
// import config from "../../configs";
// import { hslToRgb } from "@material-ui/core";
import logo from "../../assets/img/Layer x0020 1.png";

export const PayPaystackComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = localStorage.getItem("transEmailAmount");
    data = JSON.parse(data);
    setInfo(data);
  }, []);
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  const [info, setInfo] = useState("");

  const configg = {
    reference: info?.refCode,
    email: info?.email,
    amount: info?.amount,
    // publicKey: "sk_test_ndlplueh3eq7txdmzx6dkr2k0s4xoac9efrxvd2y",
    publicKey: "sk_live_id62ol4xkdovmplc5g4ff86ohs14yyzjt5pggzum",
  };

  const handleSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    history.push("/call-back");
  };

  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
    history.push("/call-back");
  };

  const componentProps = {
    ...configg,
    text: "Paystack Button Implementation",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  // const configg = {
  //   public_key: "FLWPUBK-8f81a62f610023bf89b90e0e32d7cec5-X",
  //   // public_key: config.FLUTTER_KEY,
  //   tx_ref: info?.refCode,
  //   amount: info?.amount,
  //   currency: 'NGN',
  //   redirect_url: config.FALL_BACK_ENDPOINT_FLUTTER,
  //   payment_options: 'card,mobilemoney,ussd',
  //   customer: {
  //     email: info?.email,
  //     phonenumber: info?.phonenumber,
  //   },
  // };

  // const handleFlutterPayment = useFlutterwave(configg);

  return (
    <div>
      <Navbar />
      <section className="">
        <div className="container h-100">
          <div className="flutter row">
            <div className="">
              <img src={logo} alt="" width="150" />
            </div>
            <br />
            <div className="">
              <PaystackConsumer {...componentProps}>
                {({ initializePayment }) => (
                  <Button
                    text="Pay With Paystack"
                    handleButtonClick={() =>
                      initializePayment(handleSuccess, handleClose)
                    }
                    type="button"
                    btnstyle={{
                      background: "red",
                      color: "white",
                      margin: "20px 0px",
                      // fontFamily: "Euclid Circular B",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      width: "200px",
                    }}
                  />
                )}
              </PaystackConsumer>
            </div>
          </div>
          <br />
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default PayPaystackComponent;
